export const PAGE_TITLE = "Banners";
export const PAGE_SINGLE_TITLE = "Banner";
export const LINK_URL = "banners";
export const SEARCH_TERMS = ["title"];
export const inputFields = {
  title: {
    type: "string",
    required: true,
    title: "Title",
  },
  description: {
    type: "text",
    required: true,
    title: "Description",
  },
  link: {
    type: "string",
    required: true,
    title: "Link",
  },
  featured_image: {
    type: "file",
    required: false,
    title: "Featured Image",
  },
};
export const initialValues = {
  title: "",
  description: "",
  link: "",
};

export const view_all_table = [
  { name: "Title", value: "title" },
  { name: "Description", value: "description" },
  { name: "Link", value: "link" },
];
