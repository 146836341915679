import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import { getCustomer } from '../../store/actions/customers_action';
import Spinner from '../../components/layout/Spinner';
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/customers_enum';
import SingleView from '../../components/common/SingleView';
const ViewCustomer = ({
  getCustomer,
  match,
  customer: { loading, customer },
}) => {
  useEffect(() => {
    getCustomer(match.params.id);
  }, [match]);
  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='View'
        />
        {!loading ? (
          customer && (
            <SingleView
              data={customer}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={customer.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ customer: state.customer });

const mapDispatchToProps = { getCustomer };

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
