export const PAGE_TITLE = 'Order';
export const PAGE_SINGLE_TITLE = 'Order';
export const LINK_URL = 'orders';
export const SEARCH_TERMS = ['product_key'];
export const inputFields = {
  amount: {
    type: 'string',
    required: true,
    title: 'Amount',

    inputType: 'number',
  },

  product_key: {
    type: 'string',
    required: true,
    title: 'Product Key',
    inputType: 'number',
  },

  purchased_date: {
    type: 'string',
    required: true,
    title: 'Purchased Date',
    inputType: 'date',
  },
  validity: {
    type: 'string',
    required: true,
    title: 'Validity',
    inputType: 'date',
  },
};
export const initialValues = {
  amount: '',
  product_key: '',
  purchased_date: '',
  validity: '',
};

export const view_all_table = [
  { name: 'Amount', value: 'amount' },
  { name: 'Product Key', value: 'product_key' },
  { name: 'Purchased Date', value: 'purchased_date' },
  { name: 'Validity', value: 'validity' },
];
