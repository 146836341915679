import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import {
  getTrials,
  deleteTrial,
  getNextTrials,
  getPreviousTrials,
} from '../../store/actions/trials_action';
import { LIMIT } from '../../domain/constant';
import Pagination from '../../components/common/Pagination';
import Filters from '../../components/common/Filters';
import AddBtn from '../../components/common/AddBtn';
import {
  SEARCH_TERMS,
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/trials_enum';
import DataTable from '../../components/common/DataTable';
const AllTrials = ({
  getTrials,
  deleteTrial,
  getNextTrials,
  getPreviousTrials,
  trial: { loading, trials },
}) => {
  const [page, setPage] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [q, setQ] = useState(null);
  const [term, setTerm] = useState(null);
  const [termField, setTermField] = useState(SEARCH_TERMS[0]);
  useEffect(() => {
    async function allQuery() {
      if (deleteEntry) {
        await deleteTrial(deleteEntry);
      }
      getTrials({});
    }
    allQuery();
  }, [deleteEntry]);

  const nextButtonClicked = () => {
    if (trials && trials.length === LIMIT) {
      const lastTrial = trials[trials.length - 1];
      getNextTrials({ last: lastTrial });
      setPage(page + 1);
    }
  };
  const prevBtnClicked = () => {
    if (page > 1) {
      const lastTrial = trials[0];
      getPreviousTrials({
        first: lastTrial,
      });
      setPage(page - 1);
    }
  };
  const filterClicked = () => {
    if (term && term.length > 0) {
      setPage(1);
      setQ(term);
      getTrials({ term: term, termField: termField });
    }
  };
  const deleteTrialClicked = (id) => {
    setDeleteEntry(id);
  };
  const resetFilter = () => {
    setPage(1);
    setQ(null);
    setTerm('');
    getTrials({});
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle='Dashboard'
          mainLinkUrl='/dashboard'
          activeLink={PAGE_TITLE}
        />

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div
                className='card'
                style={{ boxShadow: 'rgb(227 233 243) 0px 4px 22px' }}
              >
                <div className='card-body'>
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  <hr />

                  <Filters
                    q={q}
                    resetFilter={resetFilter}
                    setTerm={setTerm}
                    term={term}
                    filterClicked={filterClicked}
                    termField={termField}
                    setTermField={setTermField}
                    all_terms={SEARCH_TERMS}
                  />
                  <hr />
                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={trials}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteTrialClicked}
                      loading={loading}
                    />
                    <Pagination
                      data={trials}
                      page={page}
                      prevBtnClicked={prevBtnClicked}
                      nextButtonClicked={nextButtonClicked}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ trial: state.trial });

const mapDispatchToProps = {
  getTrials,
  deleteTrial,
  getNextTrials,
  getPreviousTrials,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTrials);
