export const PAGE_TITLE = 'Customer';
export const PAGE_SINGLE_TITLE = 'Customer';
export const LINK_URL = 'customers';
export const SEARCH_TERMS = ['name', 'email', 'phone'];
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    slug: true,
    inputType: 'text',
  },

  email: {
    type: 'string',
    required: false,
    title: 'Email',
  },
  phone: {
    type: 'text',
    required: false,
    title: 'Phone',
  },
};
export const initialValues = {
  name: '',
  email: '',
  phone: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Email', value: 'email' },
  { name: 'Phone', value: 'phone' },
];
