import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import { getTrial } from '../../store/actions/trials_action';
import Spinner from '../../components/layout/Spinner';
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/trials_enum';
import SingleView from '../../components/common/SingleView';
const ViewTrial = ({ getTrial, match, trial: { loading, trial } }) => {
  useEffect(() => {
    getTrial(match.params.id);
  }, [match]);
  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='View'
        />
        {!loading ? (
          trial && (
            <SingleView
              data={trial}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={trial.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ trial: state.trial });

const mapDispatchToProps = { getTrial };

export default connect(mapStateToProps, mapDispatchToProps)(ViewTrial);
