import { combineReducers } from 'redux';
import auth from './auth_reducer';
import alert from './alert_reducer';
import blog from './blog_reducer';
import banner from './banner_reducer';
import contact from './contact_reducer';
import newsletter from './newsletter_reducer';
import testimonial from './testimonial_reducer';
import customer from './customer_reducer';
import order from './order_reducer';
import trial from './trial_reducer';
export default combineReducers({
  auth,
  blog,
  alert,
  banner,
  contact,
  newsletter,
  testimonial,
  customer,
  order,
  trial,
});
