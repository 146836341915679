export const PAGE_TITLE = 'Trial';
export const PAGE_SINGLE_TITLE = 'Trial';
export const LINK_URL = 'trials';
export const SEARCH_TERMS = ['name', 'email', 'phone'];
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Title',

    inputType: 'text',
  },
  email: {
    type: 'string',
    required: true,
    title: 'Email',

    inputType: 'text',
  },
  phone: {
    type: 'string',
    required: true,
    title: 'Phone',

    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
  email: '',
  phone: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Email', value: 'email' },
  { name: 'Phone', value: 'phone' },
];
