import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import {
  getTestimonial,
  editTestimonial,
} from "../../store/actions/testimonials_action";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/testimonials_enum";

const EditTestimonial = ({
  editTestimonial,
  getTestimonial,
  match,
  testimonial: { loading, testimonial },
}) => {
  let history = useHistory();
  useEffect(() => {
    getTestimonial(match.params.id);
  }, [match]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const submitFormClicked = async (values) => {
    if (featuredImage || gallery) {
      const images = await upload(featuredImage, gallery);
      if (images) {
        if (images.featured_image) {
          values.featured_image = images.featured_image;
        }
        if (images.gallery_images) {
          values.gallery = images.gallery_images;
        }
      }
    }
    await editTestimonial(testimonial.id, values);
    history.push(`/${LINK_URL}/${testimonial.id}/view`);
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              testimonial && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {testimonial[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${testimonial.id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={testimonial}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  testimonial: state.testimonial,
});

const mapDispatchToProps = { editTestimonial, getTestimonial };

export default connect(mapStateToProps, mapDispatchToProps)(EditTestimonial);
