import { db } from '../../shared/config';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  limit,
  Timestamp,
  where,
} from 'firebase/firestore';
import {
  GET_ORDERS,
  ADD_ORDER,
  GET_ORDER,
  RESET_ORDER,
  ORDERS_ERROR,
} from '../types/order_type';

import { setAlert } from './alert';
import { LIMIT } from '../../domain/constant';
//GET ORDERS
const ordersRef = collection(db, 'orders');
export const getOrders =
  ({ term, termField, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(ordersRef, where(termField, '==', term));
      } else {
        searchQuery = query(
          ordersRef,
          orderBy(orderValue, orderType),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_ORDERS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: ORDERS_ERROR,
      });
    }
  };

export const getNextOrders =
  ({ term, termField, last, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          ordersRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT),
          where(termField, '==', term)
        );
      } else {
        searchQuery = query(
          ordersRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_ORDERS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: ORDERS_ERROR,
      });
    }
  };
export const getPreviousOrders =
  ({ term, termField, first, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          ordersRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT),
          where(termField, '==', term)
        );
      } else {
        searchQuery = query(
          ordersRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT)
        );
      }

      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_ORDERS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: ORDERS_ERROR,
      });
    }
  };

export const addOrder = (data) => async (dispatch) => {
  try {
    data.createdAt = Timestamp.fromDate(new Date());
    const docRef = await addDoc(ordersRef, data);
    dispatch({
      type: ADD_ORDER,
    });
    dispatch(setAlert('Order added successfully', 'success'));
  } catch (e) {
    console.error('Error adding document: ', e);
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: ORDERS_ERROR,
    });
  }
};

export const getOrderBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ORDER,
    });
    let searchQuery = null;
    searchQuery = query(ordersRef, where('slug', '==', slug));
    const querySnapshot = await getDocs(searchQuery);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    if (data.length > 0) {
      dispatch({
        type: GET_ORDER,
        payload: data[0],
      });
    } else {
      dispatch({
        type: GET_ORDER,
        payload: null,
      });
      dispatch({
        type: ORDERS_ERROR,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: ORDERS_ERROR,
    });
  }
};
export const getOrder = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ORDER,
    });
    const docRef = doc(db, 'orders', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      data.id = id;
      dispatch({
        type: GET_ORDER,
        payload: data,
      });
    } else {
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: ORDERS_ERROR,
      });
    }
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: ORDERS_ERROR,
    });
  }
};
export const editOrder = (id, data) => async (dispatch) => {
  try {
    const docSnap = await updateDoc(doc(db, 'orders', id), data);
    dispatch(setAlert('Order updated successfully', 'success'));
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: ORDERS_ERROR,
    });
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    const docSnap = await deleteDoc(doc(db, 'orders', id));
    dispatch(setAlert('Order deleted successfully', 'success'));
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: ORDERS_ERROR,
    });
  }
};
