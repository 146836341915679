import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Login from './containers/login/Login';
import { loadUser } from './store/actions/auth';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import Dashboard from './containers/dashboard/Dashboard';
import PageNotFound from './containers/notfound/PageNotFound';
import AddBlog from './containers/blogs/AddBlog';
import AllBlogs from './containers/blogs/AllBlogs';
import ViewBlog from './containers/blogs/ViewBlog';
import EditBlog from './containers/blogs/EditBlog';
import Profile from './containers/profile/Profile';
import AddBanner from './containers/banners/AddBanner';
import AllBanners from './containers/banners/AllBanners';
import ViewBanner from './containers/banners/ViewBanner';
import EditBanner from './containers/banners/EditBanner';
import AddContact from './containers/contacts/AddContact';
import AllContacts from './containers/contacts/AllContacts';
import ViewContact from './containers/contacts/ViewContact';
import EditContact from './containers/contacts/EditContact';
import AddNewsletter from './containers/newsletters/AddNewsletter';
import AllNewsletters from './containers/newsletters/AllNewsletters';
import ViewNewsletter from './containers/newsletters/ViewNewsletter';
import EditNewsletter from './containers/newsletters/EditNewsletter';

import AddTestimonial from './containers/testimonials/AddTestimonial';
import AllTestimonials from './containers/testimonials/AllTestimonials';
import ViewTestimonial from './containers/testimonials/ViewTestimonial';
import EditTestimonial from './containers/testimonials/EditTestimonial';

import AddCustomer from './containers/customers/AddCustomer';
import AllCustomers from './containers/customers/AllCustomers';
import ViewCustomer from './containers/customers/ViewCustomer';
import EditCustomer from './containers/customers/EditCustomer';

import AddOrder from './containers/orders/AddOrder';
import AllOrders from './containers/orders/AllOrders';
import ViewOrder from './containers/orders/ViewOrder';
import EditOrder from './containers/orders/EditOrder';

import AddTrial from './containers/trials/AddTrial';
import AllTrials from './containers/trials/AllTrials';
import ViewTrial from './containers/trials/ViewTrial';
import EditTrial from './containers/trials/EditTrial';

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path='/' component={Login} />
          <PrivateRoutes exact path='/dashboard' component={Dashboard} />
          <PrivateRoutes exact path='/profile' component={Profile} />
          <PrivateRoutes exact path='/blogs' component={AllBlogs} />
          <PrivateRoutes exact path='/blogs/add' component={AddBlog} />
          <PrivateRoutes exact path='/blogs/:id/view' component={ViewBlog} />
          <PrivateRoutes exact path='/blogs/:id/edit' component={EditBlog} />
          <PrivateRoutes exact path='/banners' component={AllBanners} />
          <PrivateRoutes exact path='/banners/add' component={AddBanner} />
          <PrivateRoutes
            exact
            path='/banners/:id/view'
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path='/banners/:id/edit'
            component={EditBanner}
          />

          <PrivateRoutes exact path='/contacts' component={AllContacts} />
          <PrivateRoutes exact path='/contacts/add' component={AddContact} />
          <PrivateRoutes
            exact
            path='/contacts/:id/view'
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path='/contacts/:id/edit'
            component={EditContact}
          />
          <PrivateRoutes exact path='/newsletters' component={AllNewsletters} />
          <PrivateRoutes
            exact
            path='/newsletters/add'
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path='/newsletters/:id/view'
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path='/newsletters/:id/edit'
            component={EditNewsletter}
          />

          <PrivateRoutes
            exact
            path='/testimonials'
            component={AllTestimonials}
          />
          <PrivateRoutes
            exact
            path='/testimonials/add'
            component={AddTestimonial}
          />
          <PrivateRoutes
            exact
            path='/testimonials/:id/view'
            component={ViewTestimonial}
          />
          <PrivateRoutes
            exact
            path='/testimonials/:id/edit'
            component={EditTestimonial}
          />
          <PrivateRoutes exact path='/customers' component={AllCustomers} />
          <PrivateRoutes exact path='/customers/add' component={AddCustomer} />
          <PrivateRoutes
            exact
            path='/customers/:id/view'
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path='/customers/:id/edit'
            component={EditCustomer}
          />

          <PrivateRoutes exact path='/orders' component={AllOrders} />
          <PrivateRoutes exact path='/orders/add' component={AddOrder} />
          <PrivateRoutes exact path='/orders/:id/view' component={ViewOrder} />
          <PrivateRoutes exact path='/orders/:id/edit' component={EditOrder} />

          <PrivateRoutes exact path='/trials' component={AllTrials} />
          <PrivateRoutes exact path='/trials/add' component={AddTrial} />
          <PrivateRoutes exact path='/trials/:id/view' component={ViewTrial} />
          <PrivateRoutes exact path='/trials/:id/edit' component={EditTrial} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
