import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getNewsletter } from "../../store/actions/newsletters_action";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/newsletters_enum";
import SingleView from "../../components/common/SingleView";
const ViewNewsletter = ({
  getNewsletter,
  match,
  newsletter: { loading, newsletter },
}) => {
  useEffect(() => {
    getNewsletter(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          newsletter && (
            <SingleView
              data={newsletter}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={newsletter.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ newsletter: state.newsletter });

const mapDispatchToProps = { getNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(ViewNewsletter);
