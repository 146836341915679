import {
  GET_TRIALS,
  ADD_TRIAL,
  EDIT_TRIAL,
  GET_TRIAL,
  RESET_TRIAL,
  TRIALS_ERROR,
} from '../types/trial_type';

const initialState = {
  trials: null,
  trial: null,
  total_trials: 0,
  loading: true,
  error: {},
  trial_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TRIALS:
      return {
        ...state,
        trials: payload,
        loading: false,
        error: {},
      };

    case RESET_TRIAL:
      return {
        ...state,
        trial: null,
        loading: true,
        error: {},
      };

    case ADD_TRIAL:
      return {
        ...state,
        trial_message: payload,
        loading: false,
        error: {},
      };
    case GET_TRIAL:
      return {
        ...state,
        trial: payload,
        loading: false,
        error: {},
      };
    case EDIT_TRIAL:
      return {
        ...state,
        trial_message: payload,
        loading: false,
        error: {},
      };

    case TRIALS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
