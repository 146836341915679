export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
  },
  {
    label: 'Customers',
    link: '/customers',
  },
  {
    label: 'Contacts',
    link: '/contacts',
  },
  {
    label: 'Newsletters',
    link: '/newsletters',
  },
  {
    label: 'Trials',
    link: '/trials',
  },
  {
    label: 'Orders',
    link: '/orders',
  },
];
